<template>
    <div class="file-box">
        <h3 class="title">
            {{ title }}
            <span v-if="showTag" style="color: red">*</span>
        </h3>
        <van-field value="上传发票" v-if="isOpenWeb" :readonly='true' type="text" right-icon="arrow" label="补充发票" input-align='right' @click.native.stop="goPay()"></van-field>
        <p style="color: #969799;font-size: 0.12rem;padding:0 .15rem .15rem 0.15rem;" v-if="uploaderSettings.tip"> {{ uploaderSettings.tip }} </p>
        <FinderFrame
            class="item-box"
            :gateway="gateway"
            :maxSize="100"
            :uploadTimeout="120000"
            :actions="isOnlyCamera"
            :geoLocation="position"
            :isPreview="true"
            :multiple="true"
            :isH5="true"
            ref="FinderFrame"
            :isDelete="isDelete"
            :maxCount="9"
            :fileArray="fileList"
            :h5FilePreview="false"
            :onlyPreview="readOnly"
            :filterData="uploaderSettings.filterData"
            :mappingData="uploaderSettings.mappingData"
            :isMapping="true"
            @uploadCallback="uploadCallback"
            @openFile="openFile"
            @deleteCallback="deleteCallback"
        />
    </div>
</template>

<script>
import {FinderFrame} from "smart-filer-vue";
import "smart-filer-vue/lib/index.css";
import AMapLoader from '@amap/amap-jsapi-loader';
import {Field} from 'vant';
export default {
    name: "iFinderFrame",
    components: {
        FinderFrame,
      [Field.name]: Field,
    },
    data() {
        return {
          gateway: process.env.VUE_APP_GATEWAY,
          position: {},
        }
    },
    props: {
        uploaderSettings: Object,
        fileList: {
            type: Array,
            default: () => {
                return []
            }
        },
        isDelete: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        showTag: {
            type: Boolean,
            default: false
        },
        onlyCamera: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
      isOpenWeb: {
        type: Boolean,
        default: false
      }
    },
  mounted() {
    window.getLocationCallback = this.getLocationCallback;
    // 加载 AMap JavaScript SDK
    AMapLoader.load({
      key: "4baaccadcc516ab6c9afd7a7d9963c7f",// 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0',
      plugins: ['AMap.Geolocation']
    }).then(() => {
      // AMap JavaScript SDK 加载完成后执行相关操作
      this.getCurrentLocation();
    });
  },
  computed : {
    isOnlyCamera () {
      if (this.onlyCamera) {
        return [
          {actionType: "carame", actionLabel: "拍照"},
        ]
      }
      return [
        {actionType: "carame", actionLabel: "拍照"},
        {actionType: "album", actionLabel: "相册", accepts: "image/*"},
      ]
    }
  },
    created() {
        // 不需要查询数据
        if(this.fileList.length > 0) {
            this.gateway = ''
        } else {
            this.gateway = process.env.VUE_APP_GATEWAY
        }
      // this.amapLocation();
    },
    methods: {
        uploadCallback(listData) {
            this.$emit('uploadFile',listData)
        },
        deleteCallback(item) {
            this.$emit('delFile',item)
        },
      getCurrentLocation() {
        // 创建 AMap.Geolocation 实例
        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认为 false
          timeout: 10000, // 超过 10 秒后停止定位，默认值为无穷大
          maximumAge: 0, // 定位结果缓存时间，默认值为 0
          convert: true, // 是否使用火星坐标转换，默认为 true
          showButton: false // 是否显示定位按钮，默认为 true
        });

        // 获取当前位置信息
        geolocation.getCurrentPosition((status, result) => {
          if (status === 'complete') {
            const { position } = result;
            const { lat, lng } = position;
            console.log('当前坐标：', lat, lng);
            console.log("====高德地图获取到的位置信息", result);
            this.position = {
              lat: lat,
              lng: lng,
            };
          } else {
            console.log('获取位置失败');
            if (window.flutter_inappwebview) {
              this.getFlutterLocationOnce()
            } else if (this.iJsBridge) {
              this.hasLocationPermission();
            }
          }
        });
      },
      amapLocation () {
        // 使用高德地图获取位置信息
        AMap.plugin("AMap.Geolocation", () => {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, //是否使用高精度定位，默认:true
            timeout: 10000, //超过10秒后停止定位，默认：5s
            position: "RB", //定位按钮的停靠位置
            offset: [10, 20], //定位按钮与设置的停靠位置的偏移量，默认：[10, 20]
            zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
          });
          geolocation.getCurrentPosition((status, georesult) => {
            if (status == "complete") {
              AMap.plugin("AMap.Geocoder", () => {
                var geocoder = new AMap.Geocoder();
                var lnglat = [georesult.position.lng, georesult.position.lat];
                geocoder.getAddress(lnglat, (status, result) => {
                  if (status === "complete" && result.info === "OK") {
                    // result为对应的地理位置详细信息
                    console.log("====高德地图获取到的位置信息", result);
                    let auditData = { province: result.regeocode.addressComponent.province, city: result.regeocode.addressComponent.city, address: result.regeocode.formattedAddress };
                    this.position = {
                      lat: georesult.position.lat,
                      lng: georesult.position.lng,
                      auditData,
                    };
                  }
                });
              });
            } else {
              console.log("------高德地图没有获取到的位置信息");
              if (window.flutter_inappwebview) {
                this.getFlutterLocationOnce()
              } else if (this.iJsBridge) {
                this.hasLocationPermission();
              }
            }
          });
        });
      },
      getLocationCallback(addressInfo) {
        console.log("原生定位回调："+addressInfo)
        console.log("原生定位回调："+JSON.stringify(addressInfo))
        console.log("+++++++++++++++++"+typeof(addressInfo))
        if(typeof(addressInfo) === "string") {
          addressInfo = addressInfo&&JSON.parse(addressInfo)
        }
        console.log("+++++++++++++++++"+addressInfo.latitude)
        this.position = {
          lat: addressInfo.lat||addressInfo.latitude,
          lng: addressInfo.lon||addressInfo.longitude
        }
        this.position = {
          lat: addressInfo.lat||addressInfo.latitude,
          lng: addressInfo.lon||addressInfo.longitude,
        };
        // }
      },
      getFlutterLocationOnce(){
        window.flutter_inappwebview.callHandler("Amap.getLocationOnce", 'getLocationCallback');
      },
      // 检查APP是否开启定位
      hasLocationPermission() {
        this.iJsBridge.call({
          method: "SMPermission.hasLocationPermission",
          postData: {},
          callback: this.hasLocationPermissionCallback
        });
      },
      hasLocationPermissionCallback(data) {
        if (data === true || data === "true") {
          // 原生定位
          this.iJsBridge.call({
            method: "SMAmap.getLocation",
            postData: {},
            callback: this.getLocationCallback
          });
        } else {
          this.$toast('请检查移动设备的定位是否开启且授权 APP可以访问')
        }
      },
      goPay () {
        this.$emit('goPay')
      },
      openFile(item) {
        let container = this.$cookie.get("container") || sessionStorage.getItem("container");
        let fileType = ''
        let isPdf = false
        switch (container) {
          case "browser":
          case "h5":
            this.$router.push({
              path: "/iframePage",
              query: {
                urlPath: item.path
              },
            });
            break;
          case "native":
          default:
            fileType = item.fileType.split(".").reverse()[0];
            isPdf = ['pdf', 'PDF'].includes(fileType.toLowerCase())
            if (window.flutter_inappwebview && isPdf) {
              window.flutter_inappwebview.callHandler(
                  "App.openPdf", item.url
              );
            } else {
              this.iJsBridge.call({
                method: "SMGeneral.openNewWebSite",
                postData: {
                  loadUrl: item.url,
                  browserMode: {
                    navBarVisibilty: true,
                    titleTextColor: "#ffffff",
                    navBackgroundColor: '#2e82ef',
                  },
                },
              });
            }
            break;
        }
      },
    }
}
</script>

<style lang="scss" scoped>
.file-box {
    background: #ffffff;
    .title {
        color:  var(--themeColor);
        box-sizing: border-box;
        padding: .1rem;
        text-align: left;
        font-size: .16rem;
    }
    .item-box {
        padding: 0 0.2rem 0.15rem 0.2rem;
        box-sizing: border-box;
        /deep/ {
            .van-icon {
                width: unset;
                height: unset;
            }
            .ff-upload-btn {
                font-size: 30px;
                height: 60px !important;
                width: 60px !important;
                color: #919191;
            }
        }
    }
}
</style>
