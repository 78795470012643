<template>
  <section>
    <sm_header title='补传文件' :smHleftEvent='closeWebview'></sm_header>
    <div style="margin-top: .45rem">
      <iFinderFrame :uploader-settings="uploaderSettings" title="水单" :readOnly="true"></iFinderFrame>
      <iFinderFrame :uploader-settings="billData" title="补传水单" ></iFinderFrame>
      <iFinderFrame :uploader-settings="invoice" title="发票" :readOnly="true"></iFinderFrame>
      <iFinderFrame :uploader-settings="invoiceData" :readOnly="true" ref="invoice" :isOpenWeb="true" title="补充发票" @goPay="goPay"></iFinderFrame>
    </div>
  </section>
</template>
<script>
import iFinderFrame from '@/components/iFinderFrame'
import {getCookie} from "tiny-cookie";
import generalBridge from "@/plugin/generalBridge.js";

export default {
  name: 'supplierList',
  components: {
    iFinderFrame
  },
  data () {
    return {
      uploaderSettings: {
        filterData: {
          processId: this.$SmartStorage.get("selectDemandItem").proposalId,
          supplierId: this.$SmartStorage.get("selectDemandItem").supplierId,
          relatedType: ["bill","bill_reback","SupplierSettle","settle"],
          catalog: ["损失费文件",'水单']
        }
      },
      invoice: {
        filterData: {
          processId: this.$SmartStorage.get("selectDemandItem").proposalId,
          supplierId: this.$SmartStorage.get("selectDemandItem").supplierId,
          relatedType: "invoice",
          catalog: "发票"
        }
      },
      billData: {
        filterData: {
          processId: this.$SmartStorage.get("selectDemandItem").proposalId,
          supplierId: this.$SmartStorage.get("selectDemandItem").supplierId,
          catalog: (this.$SmartStorage.get("selectDemandItem").lossStatus && this.$SmartStorage.get("selectDemandItem").lossStatus > 0) ?  "损失费文件" : '水单',
          relatedType: 'bill_supplement'
        }
      },
      invoiceData: {
        filterData: {
          processId: this.$SmartStorage.get("selectDemandItem").proposalId,
          supplierId: this.$SmartStorage.get("selectDemandItem").supplierId,
          catalog: "发票",
          relatedType: 'invoice_supplement'
        }
      }
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    // 返回按钮
    closeWebview() {
      this.$router.back()
    },
    async goPay () {
      let params = {
        Data: {
          eventData: {
            proposalId: this.$SmartStorage.get("selectDemandItem").proposalId,
          },
          itemData: {
            origin: 'Venue',
            orderId: this.$SmartStorage.get("selectDemandItem").orderId ? this.$SmartStorage.get("selectDemandItem").orderId : '00000000-0000-0000-0000-000000000000',
            type: '补充文件',
            proposalId: this.$SmartStorage.get("selectDemandItem").proposalId,
            itemId: this.$SmartStorage.get("selectDemandItem").supplierId,
            sessionId: this.$SmartStorage.get('sessionId'),
          },
          tenant_code: getCookie('tenant'),
          tenant_id: getCookie('tenant_id'),
          tenant: getCookie('tenant'),
          token: getCookie('token'),

        }
      }
      let res = await this.$service.saveCacheData(params)
      if (res && res.success) {
        let ipayURL = `${this.$SmartStorage.get("Uris").Uris["webapp-ipay"]}/uploadInvoice?sessionId=${res.content}`
        generalBridge.openWebAppCate(ipayURL, this.queryFile())
      }
    },
    queryFile () {
      this.$refs.invoice.$refs.FinderFrame.queryFile()
    }
  }
}
</script>
<style scoped>
.supplierHead {
  font-size: .22rem;
  font-weight: 500;
  text-align: left;
  padding: 0 0.2rem;
}
</style>
